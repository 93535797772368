import T from "prop-types";
import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/a11y";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useMediaQuery, IconButton, Box } from "components/MUI";

import theme from "config/theme";
import SingleView from "../SingleView";

const ITEMS_ON_DESKTOP = 4;
const ITEMS_ON_MOBILE = 1.4;
const PAGINATION_BUTTON_MARGIN = 70;

function CarousellView({ products, productTitleTag }) {
  const swiperRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isExtraLarge = useMediaQuery("(min-width:1375px)");

  if (products.length === 0) {
    return null;
  }

  const amountOfElements = isSmall ? ITEMS_ON_MOBILE : ITEMS_ON_DESKTOP;
  const totalPages = products.length - amountOfElements;
  const hasPrev = currentIndex - 1 >= 0;
  const hasNext = currentIndex + 1 <= totalPages;

  return (
    <Box position="relative">
      <Swiper
        modules={[A11y, Pagination]}
        spaceBetween={20}
        slidesPerView={amountOfElements}
        pagination={{ clickable: true }}
        onSlideChange={(state) => setCurrentIndex(state.activeIndex)}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {products.map((product) => (
          <SwiperSlide key={product.id}>
            <SingleView
              titleTag={productTitleTag}
              margin={theme.spacing(3)}
              {...product}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <IconButton
        aria-label="blättere zurück"
        onClick={() => swiperRef.current?.slidePrev()}
        disabled={!hasPrev}
        sx={{
          position: "absolute",
          left: -PAGINATION_BUTTON_MARGIN,
          top: "50%",
          zIndex: 1,
          display: isExtraLarge ? "block" : "none",
        }}
      >
        <ChevronLeftIcon />
      </IconButton>
      <IconButton
        aria-label="blättere vor"
        onClick={() => swiperRef.current?.slideNext()}
        disabled={!hasNext}
        sx={{
          position: "absolute",
          right: -PAGINATION_BUTTON_MARGIN,
          top: "50%",
          zIndex: 1,
          display: isExtraLarge ? "block" : "none",
        }}
      >
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
}

CarousellView.propTypes = {
  products: T.arrayOf(T.shape({})).isRequired,
  productTitleTag: T.string,
};

CarousellView.defaultProps = {
  productTitleTag: "h3",
};

export default CarousellView;
