import { Box, CardMedia, Card, CardContent, styled } from "components/MUI";
import { Small } from "components/atoms/Typography";

const PriceBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: `${theme.spacing(2)} 0 0 0`,
  gap: theme.spacing(1),
}));

const ProductImage = styled(CardMedia)(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.custom.gradient,
  height: 250,
  position: "relative",
}));

const ProductCard = styled(Card)(({ theme }) => ({
  borderRadius: 0,
  "&:hover": {
    backgroundColor: "#fff",
    boxShadow: theme.shadows[3],
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const ShortDescription = styled(Small)(({ theme }) => ({
  ...theme.typography.small,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  width: "100%",
  display: "inline-block",
}));

export {
  PriceBox,
  ProductImage,
  ProductCard,
  ShortDescription,
  StyledCardContent,
};
