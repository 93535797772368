import { Box, Container, styled } from "components/MUI";

const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => prop !== "variant",
})(({ theme, variant }) => ({
  background:
    variant === "dark"
      ? theme.palette.primary.main
      : theme.palette.custom.gradient,
  color:
    variant === "dark"
      ? theme.palette.primary.contrastText
      : theme.palette.custom.black,
}));

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "variant",
})(({ theme, variant }) => ({
  border: `1px solid ${
    variant === "dark"
      ? theme.palette.primary.contrast
      : theme.palette.primary.main
  }`,
  padding: theme.spacing(3),
  borderRadius: "2px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
}));

export { StyledContainer, StyledBox };
