import T from "prop-types";
import { Fragment, useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Image from "next/image";

import Link from "components/Link";
import theme from "config/theme";
import { H3, Caption, Text, Small } from "components/atoms/Typography";
import {
  PriceBox,
  ProductImage,
  ProductCard,
  ShortDescription,
  StyledCardContent,
} from "./SingleView.styles";

function SingleView({
  id,
  image,
  breadcrumbs,
  name,
  shortDescription,
  manufacturer,
  slug,
  price,
  priceCaption,
  titleTag,
}) {
  const [breadcrumbsList, setBreadcrumbsList] = useState([]);

  const getLinkHref = breadcrumbsList.length
    ? `/${breadcrumbsList.map((item) => item.slug).join("/")}/${slug}`
    : "/";

  useEffect(() => {
    if (!breadcrumbs.length || breadcrumbs[0] === null) {
      setBreadcrumbsList([]);
    } else {
      setBreadcrumbsList(breadcrumbs);
    }
  }, [breadcrumbs]);

  return (
    <ProductCard component="article" elevation={0}>
      <Link type="cardaction" href={getLinkHref} rel="bookmark">
        <ProductImage>
          <Image
            src={image.url}
            alt={image.alt}
            fill
            sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
            style={{
              objectFit: "contain",
              objectPosition: "center",
              padding: theme.spacing(2),
            }}
          />
        </ProductImage>
        <StyledCardContent>
          <Caption as="p" color={theme.palette.custom.darkGrey}>
            {manufacturer.name}
          </Caption>
          <H3 as={titleTag} sx={{ my: theme.spacing(0.5) }}>
            {name}
          </H3>
          {breadcrumbsList.length > 0 &&
            breadcrumbsList.map((item, index) => (
              <Fragment key={`${id}-${item.slug}`}>
                <Small>{item.name}</Small>
                {index !== breadcrumbsList.length - 1 && (
                  <NavigateNextIcon fontSize="small" />
                )}
              </Fragment>
            ))}
          {shortDescription && (
            <ShortDescription as="p">{shortDescription}</ShortDescription>
          )}
          <PriceBox>
            <Caption as="p" color={theme.palette.custom.darkGrey}>
              {priceCaption}
            </Caption>
            <Text variant="h3" component="span">
              {price}
            </Text>
          </PriceBox>
        </StyledCardContent>
      </Link>
    </ProductCard>
  );
}

SingleView.propTypes = {
  id: T.number.isRequired,
  image: T.shape({ alt: T.string, url: T.string }).isRequired,
  manufacturer: T.shape({ name: T.string }).isRequired,
  breadcrumbs: T.arrayOf(T.shape({ title: T.string, link: T.string })),
  name: T.string.isRequired,
  slug: T.string.isRequired,
  priceCaption: T.string.isRequired,
  price: T.string.isRequired,
  shortDescription: T.string,
  titleTag: T.string,
};

SingleView.defaultProps = {
  shortDescription: null,
  breadcrumbs: [],
  titleTag: "h3",
};

export default SingleView;
