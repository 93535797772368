import { forwardRef } from "react";
import T from "prop-types";
import Container from "@mui/material/Container";

import theme from "config/theme";
import { H2 } from "components/atoms/Typography";

const Section = forwardRef(
  ({ children, title, titleStyleProps, sectionStyleProps }, ref) => (
    <Container
      ref={ref}
      maxWidth="xl"
      component="section"
      {...sectionStyleProps}
    >
      {title && (
        <H2 align="center" {...titleStyleProps}>
          {title}
        </H2>
      )}
      {children}
    </Container>
  )
);

Section.propTypes = {
  children: T.node.isRequired,
  title: T.string,
  sectionStyleProps: T.shape({ sx: T.shape({}) }),
  titleStyleProps: T.shape({ sx: T.shape({}) }),
};

Section.defaultProps = {
  title: null,
  sectionStyleProps: {
    sx: { py: { md: theme.spacing(10), xs: theme.spacing(6) } },
  },
  titleStyleProps: {
    sx: {
      mb: theme.spacing(4),
    },
  },
};

export default Section;
